<template lang="html">
  <div class="main_guide">

    <div class="main_guide_sub" >
      <!-- <button id="tt" @click="open_subguide()"
      class="nav_button">Subguide</button> -->


      <router-link :style="{width:'150px'}"  :to="{ name: 'guide'}">
        <button class="nav_button">Guide Home</button>
      </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'cykel' }}">
         <button class="nav_button" >Cykel</button>
       </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'mobil' }}">
         <button class="nav_button" >Mobil</button>
       </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'dator' }}">
         <button class="nav_button" >Dator</button>
       </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'möbler' }}">
         <button class="nav_button" >Möbler</button>
       </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'kläder' }}">
         <button class="nav_button" >Kläder</button>
       </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'vitvaror' }}">
         <button class="nav_button" >Vitvaror</button>
       </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'skor' }}">
         <button class="nav_button" >Skor</button>
       </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'tv' }}">
         <button class="nav_button" >TV</button>
       </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'instrument' }}">
         <button class="nav_button" >Instrument</button>
       </router-link>
       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'kamera' }}">
         <button class="nav_button" >Kamera</button>
       </router-link>
       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'video' }}">
         <button class="nav_button" >Video</button>
       </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'klockor' }}">
         <button class="nav_button" >Klockor</button>
       </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'smycken' }}">
         <button class="nav_button" >Smycken</button>
       </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'smartwatch' }}">
         <button class="nav_button" >Smartwatch</button>
       </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'spel' }}">
         <button class="nav_button" >Spelutrustning</button>
       </router-link>
       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'kök' }}">
         <button class="nav_button" >Kök</button>
       </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'hushåll' }}">
         <button class="nav_button" >Hushåll</button>
       </router-link>

       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'trädgård' }}">
         <button class="nav_button" >Trädgård</button>
       </router-link>
       <router-link :style="{marginLeft:'2%'}"  :to="{ name: 'guidesub', params: { category: 'badrum' }}">
         <button class="nav_button" >Badrum</button>
       </router-link>


       <!-- <div for="tt" class="main_guide_sub_subcategory" v-if="this.subguide_open">
             <div class="nav_button"  v-for="(guide,idx) in subguide_list" :key="idx"  >
               <router-link :style="{marginLeft:'2%'}"
                 :to="{ name: 'guidesubsub', params: { subcategory: guide.subcategory }}">
                 <button class="nav_button"  >
                   {{guide.subcategory}}
                 </button>
               </router-link>
             </div>
       </div> -->





    </div>
  </div>

</template>

<script>
export default {

  data:function(){
    return{
      subguide_open:false,
      subguide_list:[]
    }
  },
  methods:{
    open_subguide(){
        if(this.subguide_open){
          this.subguide_open = false
        }else {
          this.subguide_open = true
        }
    },

  },
  mounted(){
    this.subguide_list=[
        {"subcategory":"Diskmaskin" },
        {"subcategory":"Kyl/frys" },
    ]

  }

}
</script>

<style lang="css" scoped>



@media (max-width:450px ){

  .main_guide{
    height: 90px;
    width: 90%;
    margin-left: 0%;
    justify-content: center;
    align-items: center;
    text-align: center;
      margin: 0 auto;
      margin-top: 2%;
  }

  .nav_button{
    border-radius: 5px;
    border: 2px solid #0A6338;
    color: #0A6338;
    display: flex;
    width: 119px;
    height: 47px;
    background-color: white;
    padding: 4px 12px 10px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .main_guide_sub{
    height:80px;
    margin-Left:0%;
    border-style:none;
    width:100%;
    overflow-x: scroll;
     display:flex;
     text-align: center;
       margin: 0 auto;

  }

}
@media (min-width:450px ){
.main_guide{
  height: 90px;
  width: 90%;
  margin-left: 0%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
    margin: 0 auto;
    margin-top: 2%;
}


.main_guide_sub{
  height:80px;
  margin-Left:0%;
  border-style:none;
  width:100%;
   display:flex;
   /* justify-content: center;
   align-items: center;
   text-align: center; */
     margin: 0 auto;

     overflow-x: scroll;

}

.main_guide_sub_subcategory{
  height:80px;
  border-style:none;
position: relative;
   flex-direction: column;

}

.nav_button{
  border-radius: 5px;
  border: 2px solid #0A6338;
  color: #0A6338;
  display: flex;
  width: 119px;
  height: 47px;
  background-color: white;
  padding: 4px 12px 10px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.nav_button_sub{
  border-radius: 5px;
  border: 2px solid #0A6338;
  color: #0A6338;
  display: flex;
  width: 119px;
  height: 47px;
  background-color: white;
  padding: 4px 12px 10px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
}
</style>
