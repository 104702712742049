<template lang="html">
  <div class="">
    <div :style="{height:'200px',position:'relative',width:'100%',bottom:'0px',backgroundColor: '#012333',color:'white'}">
      <div   :style="{height:'20px', paddingTop:'2%'}"    >
        <a href='https://www.enjord.com/about' :style="{ color:'white'}"  >
          Om Enjord
         </a>
      </div>
      <div :style="{height:'20px',marginTop:'0.5%', paddingTop:'1%'}" >
        Email: info@enjord.com
      </div>
      <!-- <div :style="{height:'20px',marginTop:'0.5%', paddingTop:'1%'}" >
        Telefon: 073 956 9253 / 073 098 11 99
      </div> -->

      <div :style="{marginTop:'1%',height:'40px',paddingTop:'2%' }">

            <a href='https://www.instagram.com/enjord_app/'   >
              <b-icon :style="{height:'25px',width:'25px',color:'white'}" icon="instagram"   />
             </a>
            <a href='https://se.linkedin.com/company/enjordreparator'  >
              <b-icon :style="{height:'25px',marginLeft:'1%',width:'25px',color:'white'}" icon="linkedin"   />
            </a>
      </div>
      <div class="copyr">
        © Enjord 2023. All rights reserved
      </div>
    </div>
  </div>
</template>

<script>
import router from '../router/index'
export default {

  methods:{
    to_about(){
      router.push({name:'About'})
    },
    click_instagram(){
      window.open("https://www.instagram.com/enjord_app/", '_blank').focus();
    }
  }
}
</script>

<style lang="css" scoped>
@media (max-width:450px ){
  .copyr{
    margin-top:  7%
  }

}

@media (min-width:450px ){
  .copyr{
    padding-top: 2%
  }

}

</style>
